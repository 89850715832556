/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery, gql } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';

interface Category {
  name: string;
}

interface CategoryCollectionResponse {
  categoryCollection: {
    items: Category[];
  };
}

// GraphQL query for fetching categories
export const CATEGORY_COLLECTION_QUERY = gql`
  query GetCategoryCollection {
    categoryCollection {
      items {
        name
        type
      }
    }
  }
`;

// Custom hook for fetching categories
export const useNewsCategories = (): Result => {
  const { called, loading, data, error } =
    useQuery<CategoryCollectionResponse>(CATEGORY_COLLECTION_QUERY);

  // Handle the Apollo error
  handleApolloError(__filename, error);

  // Return the structured response with data, loading state, and any errors
  return { called, loading, data: data ? data.categoryCollection.items : [], error };
};

// Types for the hook result
type Result = {
  data: Category[];
  called: boolean;
} & State;
