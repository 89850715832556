/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql } from '@apollo/client';

export const GET_NEWS_ARTICLES = (type, filters, limit) => {
  const f1 = type ? `type_contains_some: "${type}"` : '';
  const f2 = filters.length > 0 ? `, AND: { name_in:["${filters.join('","')}"] }` : '';

  return gql`
      query GetNewsArticles($locale: String) {
        newsArticleCollection(
          order: publishedDate_DESC
          locale: $locale
          where: { category: {   ${f1}${f2} } }
          limit: ${limit}
        ) {
          items {
            title {
              json
            }
            body {
              json
            }
            category {
              name
              type
              color
              textColor
            }
            image {
              title
              url
            }
            publishedDate
            slug
          }
          total
          
        }
      }
    `;
};
