/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { createRoot, hydrateRoot } from 'react-dom/client';
import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, ScrollRestoration } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { CmsContextProvider } from '@exo-provider/frontend-content-provider';
import { HelmetProvider } from 'react-helmet-async';
import { loadable } from '@exo/frontend-apps-base';
import { gql } from '@apollo/client';
import { Application } from './Application';
import applications from '../applications';
import { client } from '../applications';
import PiwikPro from '@piwikpro/react-piwik-pro';

// Provide navigation support to Cypress tests
if (typeof window !== 'undefined' && window.Cypress) {
  (window as any)._exoHistory = history;
}

const isSsr = process.env.CUSTOM_ENV?.includes('ssr');

// @ts-ignore
const _module = module?.hot;
const renderMethod =
  _module || !isSsr
    ? (app, container) => createRoot(container).render(app)
    : (app, container) => hydrateRoot(container, app);
const whenReady = isSsr ? loadableReady : cb => cb();

const GlobalModules = {
  newcleo: loadable(() => import('@exo/frontend-features-newcleo-ui/client/App'))
};

const GET_ROUTES_QUERY = gql`
  query {
    landingPageCollection {
      items {
        slug
      }
    }
    commonInnerPageCollection {
      items {
        slug
      }
    }
  }
`;

const LoadingPage = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img src="./static/Nucleo-animation.gif" alt="Loading ..." width={150} height={150} />
    </div>
  );
};

const Root = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .query({ query: GET_ROUTES_QUERY })
      .then(({ data: query_data }) => {
        const loadNewCleoModuleLanding = query_data.landingPageCollection.items.reduce(
          (acc, slug) => {
            acc[slug.slug] = GlobalModules.newcleo;
            return acc;
          },
          {}
        );
        const loadNewCleoModuleCommon = query_data?.commonInnerPageCollection?.items?.reduce(
          (acc, slug) => {
            acc[slug.slug] = GlobalModules.newcleo;
            return acc;
          },
          {}
        );
        applications.bundles[0].modules = {
          ...applications.bundles[0].modules,
          ...loadNewCleoModuleLanding
        };
        applications.bundles[0].modules = {
          ...applications.bundles[0].modules,
          ...loadNewCleoModuleCommon
        };
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  }, []);
  PiwikPro.initialize('2fc24583-3f25-41aa-badd-81a6ea85a67f', 'https://newcleo.piwik.pro');
  return (
    <>
      {loading ? <LoadingPage /> : null}
      {!loading && !error ? (
        <>
          <ScrollRestoration />
          <Application config={applications} />
        </>
      ) : null}
    </>
  );
};

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

const promises = CmsContextProvider?.globalCmsInit?.(applications) ?? [];
Promise.all(promises).then(() => {
  whenReady(() => {
    renderMethod(
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>,
      document.getElementById('root')
    );
  });
});
