/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery, gql } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';
import { NewsArticle } from '../types';

// Updated GraphQL query
const GET_LATEST_NEWS_DATA = gql`
  query LatestNews {
    newsArticleCollection(limit: 2, order: publishedDate_DESC) {
      items {
        title {
          json
        }
        body {
          json
        }
        category {
          name
          textColor
          color
        }
        image {
          title
          url
        }
        publishedDate
        slug
      }
    }
  }
`;

// Custom hook for fetching news articles, with optional category name filtering
export const useLatestNews = (): Result => {
  // Execute the query with the updated variables
  const { called, loading, data, error } = useQuery(GET_LATEST_NEWS_DATA);

  // Handle the Apollo error
  handleApolloError(__filename, error);
  // Format and return the hook result
  return { called, loading, data: data ? data.newsArticleCollection.items : [], error };
};

type Result = {
  data: NewsArticle[];
  called: boolean;
} & State;
